<template>
    <div>
        <v-card>
            <v-card-title>
                <div>
                    <div class="headline" >Commission report</div>
                    <span class="subtitle-1 light-grey">
                        Overview of your commission
                    </span>
                </div>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            outlined
                            color="primary"
                            @click="
                                filtersTmp = JSON.parse(JSON.stringify(filters));
                                filterDialog = true;
                            "
                        >
                            <v-icon small>fal fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filters</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <div class="text-right total-shown">
                    {{ transactions.length }} ud af {{ totalAmount | local_numbers }}
                </div>
                <commission-data-table
                    :items="transactions"
                    :loading="loading"
                    pagination
                    :has-less="page > 1"
                    :has-more="hasMore"
                    :total="total"
                    :page="page"
                    @changePage="_getTransactions($event)"
                ></commission-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="filterDialog" max-width="550px">
            <v-form @submit.prevent="filter()">
                <v-card>
                    <v-card-title class="headline" primary-title>
                        Filter report
                    </v-card-title>
                    <v-card-text>
                        <v-select
                            v-model="filtersTmp.statuses"
                            :items="statuses"
                            class="mr-10 filter-select width"
                            item-text="name"
                            item-value="value"
                            clearable
                            label="Status"
                        ></v-select>
                        <!-- <v-select
                            v-model="filtersTmp.type"
                            :items="types"
                            class="mr-10 filter-select width"
                            item-text="name"
                            item-value="value"
                            clearable
                            label="Type"
                        ></v-select> -->
                        <business-autocomplete
                            v-model="filtersTmp.business_id"
                            :return-object="false"
                        ></business-autocomplete>
                        <campaign-autocomplete
                            v-model="filtersTmp.campaign_id"
                            :return-object="false"
                            :display-draft="false"
                        ></campaign-autocomplete>
                        <date-picker
                            v-model="filtersTmp.start_date"
                            label="Start date"
                            cancel-button
                            reset-button
                        ></date-picker>
                        <date-picker
                            v-model="filtersTmp.end_date"
                            label="End date"
                            cancel-button
                            reset-button
                        ></date-picker>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" @click="filter()">
                            Filter
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import local_numbers from "@/helpers/filters/local_numbers";
    import search_filters_url from "@/helpers/mixins/search_filters_url";
    import BusinessAutocomplete from "../../../autocompletes/BusinessAutocomplete";
    import CampaignAutocomplete from "../../../autocompletes/CampaignAutocomplete";
    import CommissionDataTable from "../../data-tables/CommissionDataTable";
    import DatePicker from "@/components/common/filters/DatePicker";

    export default {
        components: {
            DatePicker,
            CommissionDataTable,
            BusinessAutocomplete,
            CampaignAutocomplete,
        },
        mixins: [search_filters_url],
        filters: { local_numbers },
        data() {
            return {
                loading: false,
                totalAmount: null,
                transactions: [],
                filterDialog: false,
                filtersTmp: {},
                filters: {},
                page: 1,
                total: null,
                limit: 50,
                hasMore: true,
                statuses: [
                    { name: "New", value: [1] },
                    { name: "Being processed", value: [2, 3, 4] },
                    { name: "Completed", value: [5] },
                ],
                types: [
                    { name: "Conversion", value: 1 },
                    { name: "Clicks", value: 2 },
                    { name: "Impressions", value: 3 },
                    { name: "Content", value: 4 },
                    { name: "Upfront", value: 5 },
                ],
            };
        },
        computed: {
            filtersCount() {
                return Object.keys(this.filters).length;
            }
        },
        watch: {
            filters: {
                handler: function () {
                    // reset
                    this.transactions = [];
                    this.page = 1;
                    this.loading = false;
                    this.hasMore = true;
                    this.setUrlSearchFilters(this.filters);
                    this._getTransactions();
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions('influencer', ['getTransactionsReport']),
            _getTransactions(page = 1) {
                const STATUSES = [
                    "New",
                    "Being processed",
                    "Being processed",
                    "Being processed",
                    "Completed",
                ];

                const TYPES = [
                    "Conversion",
                    "Clicks",
                    "Impressions",
                    "Content",
                    "Upfront",
                    "Referral",
                ];

                if (this.loading) {
                    return;
                }
                
                this.page = page;
                this.transactions = [];
                this.loading = true;

                const filters = this.cloneDeep(this.filters);

                const params = {
                    ...filters,
                    limit: this.limit,
                    page: this.page,
                    order_by: "date",
                    order: "desc"
                };

                this.getTransactionsReport(params).then(response => {
                    response.data.forEach(item => {
                        const exists = this.transactions.filter(itemTmp => itemTmp.uuid === item.uuid);

                        if (exists.length === 0) {
                            item.status = STATUSES[item.status-1];
                            item.type = TYPES[item.type-1];
                            this.transactions.push(item);
                        }
                    });

                    // If no more items
                    if (response.data.length !== params.limit) {
                        this.hasMore = false;
                    }

                    this.loading = false;
                    this.total = response.lastPage;
                    this.totalAmount = response.total;
                });
            },
            filter() {
                this.filters = this.cloneDeep(this.filtersTmp);
                this.filterDialog = false;
            },
        },
        created() {
            this.filters = this.getUrlSearchFilters();
        }
    }
</script>